import React, { useEffect, useRef, useState } from "react"
import { languageMap } from "../../sanity/languages"
import cn from "classnames"
import s from "./LanguageSelector.module.scss"
import Icon, { IconType } from "src/components/icon/Icon"
import { createFocusTrap } from "focus-trap"
import { PageReference } from "../../sanity/types"
import buildLink from "../../sanity/buildLink"
import { Container } from "src/layout/container/Container"
import { useSettings } from "src/layout/page/settingsContext"
import fetchy from "../../api/fetch"

interface LanguageSelectorProps {
    pageReferences?: PageReference[]
}

const langOrder = Object.keys(languageMap)

export default function LanguageSelector({ pageReferences }: LanguageSelectorProps) {
    const [open, setOpen] = useState(false)
    const { translations: t, settings, lang, segment } = useSettings()
    const ref = useRef<HTMLDivElement>()

    const referenceOverride = (pageReferences || []).reduce((col, pageRef) => {
        col[pageRef.lang] = buildLink(pageRef.page)
        return col
    }, {})

    const languageSettings = settings
        .reduce((collector, each, i, all) => {
            // Current language + segment
            if (each.lang === lang && each.segment === segment) {
                collector.push(each)
                return collector
            }

            // Other languages + same segment
            if (each.lang !== lang && each.segment === segment) {
                collector.push(each)
                return collector
            }

            // Other languages + other segment, and no matching segment
            if (
                each.lang !== lang
                && each.segment !== segment
                && !all.find((e) => e.lang === each.lang && e.segment === segment)
            ) {
                collector.push(each)
                return collector
            }

            return collector
        }, [] as typeof settings)
        .sort((a, b) => langOrder.indexOf(a.lang) - langOrder.indexOf(b.lang))

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [open])

    useEffect(() => {
        if (open) {
            const trap = createFocusTrap(ref.current, {
                onDeactivate: () => setOpen(false),
            })

            trap.activate()

            return () => {
                trap.deactivate()
            }
        }
    }, [open])

    const flags = {
        "dk": IconType.DK_FLAG,
        "no": IconType.NO_FLAG,
        "se": IconType.SE_FLAG,
        "en": IconType.DK_FLAG,
    }

    const changeLocale = (locale: string) => fetchy.post("/api/set-locale", {
        locale,
    })

    return (
        <>
            <div className={s["language-selector"]}>
                <button
                    className={cn(s["language-selector__button"], "label-100")}
                    onClick={() => setOpen(i => !i)}

                >
                    <Icon type={IconType.GLOBE} className={s["language-selector__button__globe"]} />
                    {languageMap[lang].name} <span className="visually-hidden">({t?.languages.change})</span>
                    <Icon type={IconType.CHEVRON_DOWN} className={s["language-selector__button__chevron"]} />
                </button>

            </div>
            <div
                ref={ref}
                style={{ display: open ? "flex" : "none", opacity: open ? "1" : "0", transition: "opacity 0.3s ease", pointerEvents: open ? "all" : "none" }}
                className={cn(s["language-selector__popup"])}
            >
                <Container>
                    <h2 className={cn(s["language-selector__title"], "heading-300")}>{t?.languages.selectLanguage}</h2>

                    <button
                        onClick={() => setOpen(false)}
                        className={cn(s["language-selector__close"], "button", "button--close")}
                    >
                        <Icon type={IconType.X} />
                        <span className="visually-hidden">{t?.languages.close}</span>
                    </button>

                    <ul className={s["language-selector__list"]} role="menu">
                        {languageSettings.filter(each => each.lang).map((each, idx) => {
                            // needs to be hard refresh for lang html prop to update properly
                            return (
                                <li
                                    key={ [each.lang, idx].join("-") }
                                    className={cn(s["language-selector__item"])}
                                >
                                    <a
                                        className={cn(s["language-selector__link"], "label-100", {
                                            [s["language-selector__link--active"]]: each.lang === lang,
                                        })}
                                        onMouseDown={ () => changeLocale(each.lang) }
                                        hrefLang={ each.lang }
                                        role="menuitem"
                                        href={ referenceOverride[each.lang] ? referenceOverride[each.lang] : buildLink(each.link) }
                                    >
                                        <Icon type={ flags[each.lang || "dk"] } /> {languageMap[each.lang]?.name}
                                        {each.lang === lang && <Icon style={{ marginLeft: "auto", marginRight: 0 }} type={IconType.CIRCLED_CHECK} />}
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </Container>
            </div>
        </>
    )
}
