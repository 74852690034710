import type { NextPage } from "next"
import React, { useEffect } from "react"
import { useSettings } from "../../layout/page/settingsContext"
import { convertLangToCulture } from "../../lib/cookieinformation"
import { Container } from "../../layout/container/Container"
import cn from "classnames"
import s from "./cookies.module.scss"

const Template: NextPage = () => {
    const { lang } = useSettings()

    useEffect(() => {
        const script = document.createElement("script")
        script.async = false
        script.id = "CookiePolicy"
        script.type = "text/javascript"
        script.dataset.culture = convertLangToCulture(lang)

        const element = document.querySelector("#cookie-policy-container")
        if (element) {
            /**
             * https://stackoverflow.com/a/16231055
             * [1] Append Script
             * [2] Set onload callback
             * [3] Set source
             */
            element.appendChild(script)
            script.onload = () => (window as any)?.CookieInformation?.loadConsent()
            script.src = "https://policy.app.cookieinformation.com/cid.js"
        }

        return () => {
            script.remove()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // Leave empty, so useEffect only runs once

    return (
        <Container>
            <div className={cn(s["page"])}>
                <div id="cookie-policy-container" />
                <div id="cicc-template"></div>
            </div>
        </Container>
    )
}

export default Template
