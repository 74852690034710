import { Section } from "src/utils"
import React, { useEffect, useState } from "react"
import s from "./RibbonModule.module.scss"
import cn from "classnames"
import { SanityCallToAction, SanityCallToActionAnonymous, SanityRichText, ThemeColor } from "src/sanity/types"
import { LinkHandler } from "src/components/link-handler/LinkHandler"
import Icon, { IconType } from "src/components/icon/Icon"
import RichText from "src/components/rich-text/RichText"
import CallToAction, { getCallToActionTrackingHandler } from "src/components/call-to-action/CallToAction"
import { Container } from "src/layout/container/Container"

interface RibbonItem {
    title: string
    id: string
}

interface RibbonModuleProps {
    ribbonType: "scrolling-news-ticker" | "ribbon"
    backgroundColor?: ThemeColor
    textColor?: ThemeColor
    list: RibbonItem[]
    cta?: SanityCallToActionAnonymous
    staticRibbonText: SanityRichText
    staticRibbonCta?: SanityCallToAction
    className?: string
}

export default function RibbonModule({
    ribbonType,
    list,
    staticRibbonText,
    staticRibbonCta,
    backgroundColor,
    textColor,
    cta,
    className,
}: RibbonModuleProps): JSX.Element {
    const [animationSpeed, setAnimationSpeed] = useState(0)

    useEffect(() => {
        if (list && list.length) {
            const listLength = list?.reduce((acc, curr) => {
                return acc + curr.title.length
            }, 0)
            setAnimationSpeed(listLength * 0.3)
        }
    }, [])

    const listWithIconsInbetween = list?.reduce((acc, curr, i) => {
        return [...acc, <Icon key={curr.id + i + "icon"} type={IconType.ARROW_RIGHT} />, curr.title]
    }, [] as (JSX.Element)[])

    const backgroundContrastColorForCta = ["primary-blue", "primary-orange", "black", "paas-blue"].includes(backgroundColor)


    return (
        <Section>
            <div
                className={cn(s["ribbon-module"], `bg-${backgroundColor}`, `text-${textColor}`, className)}
                style={{ "--animation-speed": `${animationSpeed}s` } as React.CSSProperties}
            >
                {/* Scrolling news ticker */}
                {ribbonType === "scrolling-news-ticker" && (
                    <LinkHandler link={cta.link} className={cn(s["ribbon-module__link"])}>
                        <div
                            className={cn(s["ribbon-module__inner"])}
                        >
                            <div className={cn(s["ribbon-module__inner__animating"])}>
                                <span className={cn(s["ribbon-module__inner__text"], "heading-100")}>
                                    {listWithIconsInbetween.map((item, i) => (<span key={"duplicate" + i}>{item}</span>))}
                                </span>
                            </div>
                            <div className={cn(s["ribbon-module__inner__animating"])}>
                                <span className={cn(s["ribbon-module__inner__text"], "heading-100")}>
                                    {listWithIconsInbetween.map((item, i) => (<span key={"duplicate" + i}>{item}</span>))}
                                </span>
                            </div>
                            <div className={cn(s["ribbon-module__inner__animating"])}>
                                <span className={cn(s["ribbon-module__inner__text"], "heading-100")}>
                                    {listWithIconsInbetween.map((item, i) => (<span key={"duplicate" + i}>{item}</span>))}
                                </span>
                            </div>
                        </div>
                    </LinkHandler>

                )}


                {/* Static ribbon */}
                {ribbonType === "ribbon" && (
                    <Container>
                        <div className={cn(s["ribbon-module__static__inner"])}>
                            <LinkHandler link={staticRibbonCta?.link} className={cn(s["ribbon-module__link"])} onMouseDown={getCallToActionTrackingHandler(staticRibbonCta?.link)}>
                                <RichText blocks={staticRibbonText} className={cn(s["ribbon-module__static__inner__text"])} />
                            </LinkHandler>
                            <CallToAction {...staticRibbonCta} className={cn(s["ribbon-module__static__inner__cta"])} appearance={backgroundContrastColorForCta ? "light" : null} />
                        </div>
                    </Container>
                )}
            </div>
        </Section>
    )
}
