import React from "react"
import cn from "classnames"
import s from "./VideoSmart.module.scss"
import { SanityVideo } from "../../sanity/types"
import { useInView } from "react-intersection-observer"
import Vimeo from "@u-wave/react-vimeo"
// import dynamic from "next/dynamic"
// const Vimeo = dynamic(() => import("@u-wave/react-vimeo"))

interface VideoSmartProps extends SanityVideo {
    containerSize: [number, number]
    videoWithoutBorders?: boolean
}

// "smart" - can take a width and height to set custom ratio and will then fill the entire
// box potentially overflowing and cropping video
export default function VideoSmart({
    width,
    height,
    src,
    autoplay,
    containerSize,
    loop,
    thumbnail,
    muted = true,
    controls = false,
}: VideoSmartProps): JSX.Element {
    const { ref: inViewRef, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "500px",
    })

    const videoRatio = width / height
    const containerRatio = containerSize[0] / containerSize[1]

    let w, h
    if ( containerRatio > videoRatio) {
        // Container is wider than video
        w = "100%"
        h = Math.ceil(containerRatio / videoRatio * 100) + "%"
    } else {
        // Video is wider than container
        w = Math.ceil(videoRatio / containerRatio * 100) + "%"
        h = "100%"
    }


    return (
        <div
            className="video-wrapper"
            style={{
                position: "relative",
                aspectRatio: containerSize[0] && containerSize[1] ? containerSize[0] / containerSize[1] : undefined,
                width: "100%",
                ...(thumbnail?.url ? { "backgroundImage": `url(${thumbnail.url})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" } : {}),
            }}
            ref={inViewRef}
        >
            {inView && <Vimeo
                id={src}
                video={src}
                autoplay={autoplay}
                quality="720p"
                className={cn(s["video-smart"])}
                style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    "--aspect-ratio-width": w,
                    "--aspect-ratio-height": h,
                } as React.CSSProperties}
                muted={muted}
                loop={loop}
                dnt={true}
                width={width}
                height={height}
                controls={controls}
                aria-hidden
            /> }
        </div>
    )
}
