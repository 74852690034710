import React from "react"
import cn from "classnames"
import s from "./Icon.module.scss"
import { IconType, IconSvgs } from "./IconDefinitions"

export { IconType }

export default function Icon({
    type,
    fill = false,
    className,
    style = {},
}: {
    type: IconType
    fill?: boolean
    className?: string
    style?: React.CSSProperties
}) {
    if (!IconSvgs[type])
        return <></>

    const config: {viewBox: string, fill?: boolean, definition: JSX.Element} = IconSvgs[type]

    return (
        <svg
            className={cn(s.icon, className, s["icon--" + type], {
                [s["icon--fill"]]: fill,
                [s["icon--no-fill"]]: config?.fill === false,
            })}

            fill={config.fill === false ? "none" : null}

            viewBox={config.viewBox?.split(" ")?.length >= 3 ? config.viewBox : "0 0 " + config.viewBox}
            style={style}
        >
            {config.definition}
        </svg>
    )
}
