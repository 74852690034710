import React, { ReactNode } from "react"
import cn from "classnames"
import Icon, { IconType } from "../icon/Icon"

interface ButtonProps {
    children: ReactNode
    arrow?: boolean
    arrowLeft?: boolean
    pill?: boolean
    onClick?: (any) => void
    className?: string
    appearance?: "secondary" | "dark" | "light"
    center?: boolean
    disabled?: boolean
    handleOnPointerEnter?: () => void
    handleOnPointerLeave?: () => void
    onMouseDown?: (any) => void
    onMouseEnter?: (any) => void
}

export const Button = ({
    children,
    pill = false,
    center = false,
    arrow = false,
    arrowLeft = false,
    disabled = false,
    appearance = null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick = () => {},
    handleOnPointerEnter,
    handleOnPointerLeave,
    onMouseDown,
    onMouseEnter,
    ...props
}: ButtonProps): JSX.Element => {
    return (
        <button
            type="button"
            onPointerEnter={handleOnPointerEnter}
            onPointerLeave={handleOnPointerLeave}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
            className={cn(props.className, "label-100", {
                "button--pill": pill,
                "button--center": center,
                "arrow-link--right": arrow,
                "arrow-link--left": arrowLeft,
                [`button--${appearance}`]: appearance,
            })}
            onClick={onClick}
            disabled={disabled}
        >
            <>
                {arrowLeft ? <Icon type={IconType.ARROW_LEFT} />:null } {children} {arrow ? <Icon type={IconType.ARROW_RIGHT} />:null}
            </>
        </button>
    )
}
