
import React, { useEffect, useState } from "react"
import s from "./AppStore.module.scss"
import cn from "classnames"
import { Link } from "src/components/link/Link"
import { DownLoadDetails, FooterDetails } from "src/sanity/types"
import { DeviceType, getMobileOperatingSystem, useEnvironment } from "src/utils"
import { trackAppBadge, getGaIds } from "../../lib/tagmanager"
import { createAppLinkUrl, useOneclickTracking } from "../../lib/oneclick"
import { useRouter } from "next/router"
import { useSettings } from "src/layout/page/settingsContext"
import { onConsentReady } from "src/lib/cookieinformation"

function showAppStore() {
    return getMobileOperatingSystem() === DeviceType.IOS || !getMobileOperatingSystem()

}
function showGooglePlay() {
    return getMobileOperatingSystem() === DeviceType.ANDROID || !getMobileOperatingSystem()
}

interface AppStoreProps {
    title?: DownLoadDetails["title"]
    className?: string
    linkAppStore?: FooterDetails["linkAppStore"]
    linkGooglePlay?: FooterDetails["linkGooglePlay"]
    orientation?: "horizontal" | "vertical"
    onelinkUrl?: string
    testVariation?: string
}

export default function AppStore({ title, className, linkAppStore, linkGooglePlay, orientation, onelinkUrl, testVariation }: AppStoreProps) {
    const { linkAppStore: defaultLinkAppStore, linkGooglePlay: defaultLinkGooglePlay } = useSettings()
    linkAppStore = linkAppStore || defaultLinkAppStore
    linkGooglePlay = linkGooglePlay || defaultLinkGooglePlay

    const { ids, source, campaign } = useOneclickTracking()
    const appLinkParams = { ids, source, campaign, onelinkUrl, testVariation }
    const { locale } = useRouter()

    const { client } = useEnvironment()

    const [gaIds, setGaIds] = useState({})
    useEffect(() => {
        updateGaClientIdOnConsentReady()
    }, [])

    async function updateGaClientIdOnConsentReady() {
        await onConsentReady()
        setGaIds(await getGaIds())
    }

    return (
        <div className={cn(s["app-store"], s[`app-store__${orientation}`], className)} onMouseDown={async () => {setGaIds(await getGaIds())}}>
            {title && <h2 className={cn(s["app-store__title"], "heading-200")}>{title}</h2>}
            {client && linkAppStore && showAppStore() && (
                <Link href={createAppLinkUrl({ ...appLinkParams, ids: { ...ids, ...gaIds }, fallbackUrl: linkAppStore })} className={s["app-store__link"]} onClick={() => trackAppBadge("ios-app-store")} >
                    <img
                        alt="App Store"
                        className={s["footer__app__icon"]}
                        src={`/app/Apple_${locale.toUpperCase()}.svg`}
                    />
                </Link>
            )}
            {client && linkGooglePlay && showGooglePlay() && (
                <Link href={createAppLinkUrl({ ...appLinkParams, ids: { ...ids, ...gaIds }, fallbackUrl: linkGooglePlay })} className={s["app-store__link"]} onClick={() => trackAppBadge("google-play-store")} >
                    <img
                        alt="Google Play"
                        className={s["footer__app__icon"]}
                        src={`/app/Google_${locale.toUpperCase()}.svg`}
                    />
                </Link>
            )}
        </div>
    )
}
