import React, { useEffect, useState } from "react"

export default function PreviewDisclaimer() {
    const [hideDisclaimer, setHideDisclaimer] = useState(false)
    const [encodedPath, setEncodedPath] = useState("")
    useEffect(() => {
        setEncodedPath(encodeURIComponent(typeof window !== "undefined" ? location.pathname : ""))
    }, [])

    if (hideDisclaimer) return null

    return (
        <div
            style={{
                position: "fixed",
                zIndex: 1000,
                padding: "1.25em 2em 1em",
                backgroundColor: "#ffe9cb",
                color: "black",
                bottom: "0",
                // left: "60%",
                right: "0",
                textAlign: "center",
                fontSize: "1em",
                textDecoration: "none",
            }}
        >
                    You are viewing a preview of this unpublished page.
            <span onClick={() => setHideDisclaimer(true)} style={{ position: "absolute", right: "10px", top: "10px", cursor: "pointer" }}>✕</span>
            <br />
            <button style={{
                border: "1px solid",
                margin: "12px 12px 0px",
                padding: "2px 15px",
                borderRadius: "10000px",
                height: "33px",
                display: "inline-block",
            }} onClick={async () => { navigator.clipboard.writeText(`${location.origin.replace("http://localhost:3000", "https://lunar-website.vercel.app").replace("http://local.com:3000", "https://lunar-website.vercel.app")}/api/preview?secret=skl5YqQjvRlhgQ4JlYhmh9GbRsC1mhDvSUJu3iSSo7PM2fcLppyFUegf8qsVNicJVvKHBcmbFFN6Ten4VDYa1IKNXfhr49lU3mBdiFMqkOCCQU72cYQGeArfaf0JzUrDt3SRISlaFB0WFoBZAM2Ev9ScftMLUnDiRmFSTSqkNpoccxqsuc9T&slug=${encodedPath}`)}}>
                <img height={"26px"} style={{ verticalAlign: "middle" }} src="https://upload.wikimedia.org/wikipedia/commons/c/c8/Ei-share-apple.svg" />Copy preview link for sharing
            </button>
            <button style={{
                border: "1px solid",
                margin: "12px 12px 0px",
                padding: "2px 15px",
                borderRadius: "10000px",
                height: "33px",
                display: "inline-block",
            }}>
                <a
                    href={`/api/exit-preview?slug=${encodedPath}`}
                >
                    Exit preview
                </a>
            </button>
        </div>
    )
}
