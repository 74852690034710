import React, { createContext, LegacyRef, useEffect, useLayoutEffect, useRef, useState } from "react"
import { GlobalSettingsLinkGroup, LinkItem, Locale, SanityMedia, SanityRichText, ThemeColor } from "./sanity/types"
import { languageMap } from "./sanity/languages"


export function formatDate(date: Date, locale: Locale) {
    return date.toLocaleDateString(languageMap[locale].intlLocale, { year: "numeric", month: "long", day: "numeric" })
}
export const isMobile = typeof window === "undefined" ? true : /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|Mobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)

interface OnlyProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if: any
    children: React.ReactNode
}

export function Only(props: OnlyProps): JSX.Element {
    return props.if ? <>{props.children}</> : null
}

// Source: https://medium.com/@Heydon/managing-heading-levels-in-design-systems-18be9a746fa3
const Level = createContext(1)

export function Section({ children }: { children: React.ReactNode, className?: string }): JSX.Element {
    return (
        <Level.Consumer>
            {level => {
                return <Level.Provider value={level + 1}>{children}</Level.Provider>
            }}
        </Level.Consumer>
    )
}

export function Heading(props: React.HTMLProps<HTMLHeadingElement>): JSX.Element {
    return (
        <Level.Consumer>
            {level => {
                const Component = `h${Math.min(level, 6)}`
                return React.createElement(Component, { ...props, className: (props.className || "") + " balance-text" })
            }}
        </Level.Consumer>
    )
}

export function clamp(number: number, min: number, max: number): number {
    return Math.max(min, Math.min(number, max))
}

export function easeInOutCubic(x: number): number {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
}

export function useArrayRef<T>(): [React.MutableRefObject<T[]>, LegacyRef<T>] {
    const refs = useRef<T[]>([])
    refs.current = []

    return [refs, ((ref: T) => ref && refs.current.push(ref))]
}

export function useAnimationFrame(callback: (delta: number) => void, deps = []) {
    // Use useRef for mutable variables that we want to persist
    // without triggering a re-render on their change
    const requestRef = React.useRef<number>()
    const previousTimeRef = React.useRef<number>()

    const animate = time => {
        if (previousTimeRef.current != undefined) {
            const deltaTime = time - previousTimeRef.current

            callback(deltaTime)
        }

        previousTimeRef.current = time
        requestRef.current = requestAnimationFrame(animate)
    }

    React.useEffect(() => {
        requestRef.current = requestAnimationFrame(animate)

        return () => cancelAnimationFrame(requestRef.current)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps) // Make sure the effect runs only once
}


export const useIsomorphicLayoutEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect

export enum DeviceType {
    ANDROID = "Android",
    IOS = "iOS",
}

export function getMobileOperatingSystem() {
    if (!global.window) {
        return
    }

    const userAgent = navigator.userAgent || navigator.vendor

    if (/android/i.test(userAgent)) {
        return DeviceType.ANDROID
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return DeviceType.IOS
    }

    return
}

export function useEnvironment() {
    const [hasWindow, setHaswindow] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        setHaswindow(!!global.window)
    }, [])

    return {
        client: hasWindow === true,
        server: hasWindow === false,
    }
}

function isLinkGroup(navitem: LinkChild): navitem is ModifiedLinkGroup {
    return navitem.type === "linkGroup"
}

function isLinkGroupCollector(navitem: LinkChild): navitem is LinkCollector<LinkChild> {
    return navitem.type === "linkCollector"
}

export interface LinkCollector<T> {
    type: "linkCollector"
    children: T[]
}

export type ModifiedLinkGroup = Omit<GlobalSettingsLinkGroup, "children"> & {
    children: LinkChild[]
}
export type NavigationLinkChild = LinkItem & {
    markAsNew?: boolean
    media?: SanityMedia
    isFeatureLink?: boolean
    subtitle?: string
}

export type LinkChild = ModifiedLinkGroup | NavigationLinkChild | LinkCollector<LinkChild>

export function buildMenus(navItems: LinkChild[]): LinkChild[] {
    // Hide crypto on pages from Google Ads - either manually added param "nc" (No Crypto), or automatically added "gclid" param
    const hideCrypto = typeof window !== "undefined" && (location.search.includes("nc") || location.search.includes("gclid"))

    return navItems.reduce((collector, navItem: LinkChild, index) => {
        if (navItem.type === "link") {
            if (hideCrypto) {
                const isCryptoLink = navItem.linkType === "internal" && navItem.url.slug.includes("rypto")
                if (hideCrypto && isCryptoLink) return collector
            }

            collector.push(navItem)
            return collector
        }

        if (isLinkGroupCollector(navItem)) {
            collector.push(navItem)
            return collector
        }

        const prevItem = navItems[index - 1]
        const nextItem = navItems[index + 1]

        if (navItem.groupWithPrevious) {
            if (prevItem && isLinkGroup(prevItem) && prevItem.groupWithPrevious) {
                // If we've already grouped with previous, add item to the previous group
                const previousCollectorItem = collector[collector.length - 1]

                if (previousCollectorItem && previousCollectorItem.type === "linkCollector") {
                    previousCollectorItem.children = [...previousCollectorItem.children, navItem]
                }
            }

            // Otherwise, this should already be processed, skip group
            return collector
        }

        if (navItem.children) {
            navItem.children = buildMenus(navItem.children)
        }


        const linkGroupCollector: LinkCollector<LinkChild> = {
            type: "linkCollector",
            children: [{ ...navItem }],
        }

        if (nextItem && isLinkGroup(nextItem) && nextItem.groupWithPrevious) {
            if (nextItem.children) {
                nextItem.children = buildMenus([...nextItem.children])
            }
            linkGroupCollector.children.push({ ...nextItem })
        }

        collector.push(linkGroupCollector)

        return collector
    }, [] as LinkChild[])
}

export function cleanBuiltMenus(menu: LinkChild[]): LinkChild[] {
    return menu.map(menuItem => {
        if (menuItem.type !== "link" && menuItem.children.length === 1) {
            return menuItem.children[0]
        } else {
            return menuItem
        }
    })
}

export const debounce = (callback, wait) => {
    let timeoutId = null
    return (...args) => {
        window.clearTimeout(timeoutId)
        timeoutId = window.setTimeout(() => {
            callback(...args)
        }, wait)
    }
}

export function getTextContrastColor(bgColor: ThemeColor) {
    const map: Partial<Record<ThemeColor, string>> = {
        "primary-blue": "text-white",
        "sky": "text-white",
    }

    return map[bgColor] || null
}

export function isVideoMedia(media: SanityMedia) {
    return media?.type && (media.type ===  "video" || media.type === "vimeoFiles")
}

export function jsonLog(log: { [key: string]: any }) {
    const fullLog = {
        time: Date.now(),
        level: 30,
        ...log,
    }
    console.log(JSON.stringify(fullLog))
}

// Rich text to plain text
export function richTextToString(blocks = []) {
    return blocks
    // loop through each block
        ?.map(block => {
        // if it's not a text block with children,
        // return nothing
            if (block._type !== "block" || !block.children) {
                return ""
            }
            // loop through the children spans, and join the
            // text strings
            return block.children.map(child => child.text).join("")
        })
    // join the paragraphs leaving split by two linebreaks
        ?.join("\n\n")
}

export function stringToRichText(str: string): SanityRichText {
    const strings = str?.split("\n").map(s => s.trim()) || []

    return strings.map(string => ({
        "_key": string || Math.random().toString(36).substring(7),
        "_type": "block",
        "children": [{
            "_key": string || Math.random().toString(36).substring(7),
            "_type": "span",
            "marks": [],
            "text": string || "",
        }],
        "markDefs": [],
        "style": "normal",
    }))
}

export function arrayToRichTextBullets(arr: string[]): SanityRichText {
    return arr.map(string => ({
        "_key": string || Math.random().toString(36).substring(7),
        "_type": "block",
        Level: 1,
        "listItem": "bullet",
        "children": [{
            "_key": string || Math.random().toString(36).substring(7),
            "_type": "span",
            "marks": [],
            "text": string || "",
        }],
        "markDefs": [],
        "style": "normal",
    }))
}
