import Config from "src/Config"
import { ExternalLink, InternalLink } from "./types"

const segments = {
    private: {
        dk: "privat",
        en: "personal",
        se: "privat",
        no: "privat",
    },
    business: {
        dk: "erhverv",
        en: "business",
        se: "foretag",
        no: "bedrift",
    },
}

export function buildSegmentPath({ segment, lang }: {segment: string, lang: string}): string {
    return segments[segment][lang]
}

export default function buildLink(link: InternalLink | ExternalLink): string {
    if (!link?.url) {
        return undefined
    }

    if (link.linkType === "external") {
        return link.url.replace("?passparams", "")
    }

    const { segment, lang, slug } = link.url

    let domain = Config.BASE_URL
    if (lang === "se" && domain.includes(".app"))
        domain = "https://www.lunar.se"
    else
        domain = domain + (lang ? "/" + lang : "")

    if (segment && !slug) {
        // frontpage
        return `${domain}/${buildSegmentPath({ segment, lang })}`
    }

    if (segment) {
        // page
        return `${domain}/${buildSegmentPath({ segment, lang })}/${slug}`
    }

    return `${domain}/${slug}`
}
