import Config from "src/Config"

export const config = {
    /**
   * Find your project ID and dataset in `sanity.json` in your studio project.
   * These are considered “public”, but you can use environment variables
   * if you want differ between local dev and production.
   *
   * https://nextjs.org/docs/basic-features/environment-variables
   **/
    dataset: Config.SANITY_DATASET,
    projectId: Config.SANITY_PROJECT_ID,
    apiVersion: "2021-03-25",
    /**
   * Set useCdn to `false` if your application require the freshest possible
   * data always (potentially slightly slower and a bit more expensive).
   * Authenticated request (like preview) will always bypass the CDN
   **/
    useCdn: Config.USE_CDN,
}
