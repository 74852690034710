"use client"

import React, { useEffect } from "react"
import { GetStaticPropsContext, GetStaticPropsResult } from "next"
import * as Sentry from "@sentry/react"
import ErrorPage from "next/error"
import segments from "src/sanity/segments"

import { DocTypeMap, TemplateComponent, TemplateMap, TemplateType } from "../src/templates"
import { getClient, getGlobalSettings } from "../src/sanity/sanity.server"
import Page from "src/layout/page/Page"
import CookiePage from "src/templates/cookies/index"
import * as selectors from "../src/sanity/selectors"
import { getQueryFromSlug } from "src/sanity/getQueryFromSlug"
import { BasePage } from "src/sanity/types"
import generateSeoData from "src/sanity/generateSeoData"
import Config from "../src/Config"
import { groq } from "next-sanity"
import { getTranslation } from "src/lib/translations"
import buildLink from "src/sanity/buildLink"
import PreviewDisclaimer from "src/components/preview-disclaimer/PreviewDisclaimer.module"
import { useLiveQuery } from "@sanity/preview-kit"

// we do not restrict selects to  a single item,
// so that drafts will show up too, if they do we pick those over non drafts
// so initial data and data updates reflect draft document, not published document.
export function pickDraft<T>(data: any, preview: boolean): T {
    if (!Array.isArray(data)) {
        return data
    }

    if (data.length === 1) {
        return data[0]
    }

    if (preview) {
        return data.filter(Boolean).find((item) => (item?.id || item?._id).startsWith(`drafts.`)) || data[0]
    }

    return data[0] || null
}

function isDoctype<T extends keyof DocTypeMap>(match: T, docType: keyof DocTypeMap, data: DocTypeMap[typeof docType]): data is DocTypeMap[T] {
    return docType === match
}

export default function Catchall({
    initialData,
    docType,
    resolvedUrl,
    locale,
    globalSettings,
    query,
    queryParams,
    preview,
}: BasePage<DocTypeMap[TemplateType]>): JSX.Element {
    useEffect(() => {
        Sentry.setContext("Next.js", {
            clientside: true,
            isPreview: preview,
        })
    }, [preview])

    const [liveQueryData] = useLiveQuery(initialData, query, queryParams)
    const data = pickDraft<DocTypeMap[typeof docType]>(liveQueryData, preview)

    const Component = TemplateMap[docType] as TemplateComponent<DocTypeMap[typeof docType]>

    useEffect(() => {
        Sentry.setContext("Sanity data", {
            docType,
            pageId: data?.id,
            settingsId: globalSettings?.id,
            queryParams,
        })
    }, [data?.id, docType, globalSettings?.id, preview, queryParams])

    const SEO_URL = `${locale === "se" ? "https://www.lunar.se" : Config.BASE_URL}${locale === "se" ? "" : "/" + locale}/${resolvedUrl}`.replace(/(personal|privat)\/shareit/, "shareit")

    if (resolvedUrl?.includes("/cookies")) {
        return (
            <Page
                {...generateSeoData({
                    globalSettings,
                    url: SEO_URL,
                })}
                globalSettings={globalSettings}
                headerStyle={"dark"}
            >
                <CookiePage />
            </Page>
        )
    }

    // no longer needed??
    if (!data || Array.isArray(data) && data.length === 0) {
        Sentry.captureMessage("Page not found", {
            level: "warning",
            tags: {
                url: SEO_URL,
            },
        })

        return (
            <Page
                globalSettings={globalSettings}
                headerStyle={"dark"}
            >
                <ErrorPage statusCode={404} />
            </Page>
        )
    }

    if (isDoctype("blogPost", docType, data)) {
        const canonicalUrl = buildLink(data.link)

        return (
            <Page
                {...generateSeoData({
                    globalSettings,
                    url: canonicalUrl,
                    data: {
                        ...data,
                        seo: {
                            title: data.title + " Lunar Blog",
                            description: data.intro,
                            imageUrl: data.mainImage.src,
                            type: "article",
                        },
                    },
                })}
                globalSettings={globalSettings}
                headerStyle={"dark"}
                documentId={data.id}
            >
                <Component pageData={data} />
                {!!preview && (
                    <PreviewDisclaimer />
                )}
            </Page>
        )
    }

    if (isDoctype("blogOverview", docType, data)) {
        return (
            <Page
                {...generateSeoData({
                    globalSettings,
                    url: SEO_URL,
                    data: {
                        ...data,
                        seo: {
                            title: `Blog`,
                            description: getTranslation("blog", globalSettings.lang).overviewSubtitle,
                        },
                        title: `Blog`,
                    },
                })}
                globalSettings={globalSettings}
                headerStyle={"dark"}
            >
                <Component pageData={initialData} />
            </Page>
        )
    }

    if (isDoctype("inAppPage", docType, data)) {
        return (
            <Page globalSettings={globalSettings} docType="inAppPage" headerStyle="light" pageTitle={data.title} pageThemeType={data.pageThemeType} noIndex={true} documentId={data.id} >
                <Component pageData={data} />
            </Page>
        )
    }

    return (
        <Page
            {...generateSeoData({
                globalSettings,
                url: SEO_URL,
                data: data,
            })}
            globalSettings={globalSettings}
            pageReferences={data?.refs}
            headerStyle={data.headerStyle}
            pageThemeType={data.pageThemeType}
            pageThemeColor={data.pageThemeColor}
            backgroundImage={isDoctype("page", docType, data) && data.backgroundImage}
            docType={docType}
            documentId={data.id}
        >
            <Component pageData={data} />
            {!!preview && (
                <PreviewDisclaimer />
            )}
        </Page>
    )
}

export async function getStaticProps({
    locale,
    params,
    preview = false,
    previewData,
}: GetStaticPropsContext<Record<string, string | string[]>, {token: string}>): Promise<GetStaticPropsResult<BasePage<DocTypeMap[TemplateType]>>> {
    const slug = Array.isArray(params.slug) ? params.slug : [params.slug]
    const [segment] = slug
    if (locale === "default") {
        // This might happen if a top-level page with a dot in is requested,
        // due to how the middleware redirect setup is implemented. Example: /foo.abc.defg
        locale = "en"
    }

    // // If there's no segment, or the segment is incorrect - redirect to private segment
    // if (!segment || (![segments[locale].private, segments[locale].business].includes(segment) && !slug.includes("shareit"))) {
    //     console.log("Redirecting to site with segment", { locale, segment, slug }, `/${locale}/${segments[locale].private}/${slug.join("/")}`)
    //     return {
    //         redirect: {
    //             destination: `/${locale}/${segments[locale].private}/${slug.map(encodeURIComponent).join("/")}`,
    //             permanent: true,
    //         },
    //     }
    // }

    const validSegments = [segments[locale].private, segments[locale].business]
    if (!validSegments.includes(segment)) {
        return {
            revalidate: 60 * 60 * 24, // 24 hours
            notFound: true,
        }
    }

    const isPreview = preview || false
    const { docType, query, queryParams } = await getQueryFromSlug(
        params.slug as string[],
        locale,
        preview,
    )

    Sentry.setContext("Sanity query", queryParams)
    Sentry.setContext("Next.js", {
        serverside: true,
        isPreview: isPreview,
    })

    const client = getClient(isPreview, false)
    const [globalSettings, pageData] = await Promise.all([
        getGlobalSettings(selectors.globalSettings, queryParams),
        client.fetch<DocTypeMap[keyof DocTypeMap]>(query, queryParams),
    ])

    // TODO: Implement proper 404 page
    if ((segment !== "cookies" && !pageData) || ((Array.isArray(pageData) && pageData.length === 0)) || (isDoctype("blogOverview", docType, pageData) && pageData.blogPosts.length === 0)) {
        return {
            revalidate: 60 * 60 * 24, // 24 hours
            notFound: true,
        }
    }

    return {
        props: {
            initialData: pickDraft(pageData, isPreview),
            globalSettings,
            docType,
            preview: isPreview,
            ...(previewData ? { previewData } : {}),
            slug: slug,
            resolvedUrl: slug.join("/"),
            locale: locale,
            query: isPreview ? query : "",
            queryParams,
        },
        revalidate: process.env.VERCEL_ENV === "production" ? 60 * 60 * 24 : 10, // 24 hours on prod, but also revalidated on demand
    }
}

export async function getStaticPaths() {
    const maxCount = 175
    const frontpageQuery = groq`*[_type == "frontpage" && !(_id in path("drafts.**"))]{
        segment,
        lang,
        "slug": slug.current,
    }`
    const pageQuery = groq`*[_type == "page" && !(_id in path("drafts.**"))] | order(_updatedAt desc) {
        segment,
        lang,
        sorting,
        "slug": slug.current,
    } [0...${maxCount}]`
    const blogPostQuery = groq`*[_type == "blogPost" && !(_id in path("drafts.**"))]{
        segment,
        lang,
        lang in ["dk", "en"] => {
            "slug": "blog/" + category->slug.current + "/" + slug.current
        },
        lang in ["se", "no"] => {
            "slug": "blogg/" + category->slug.current + "/" + slug.current
        },
    }[0...${maxCount}]`
    const client = getClient(false)
    type PageObject = {
        slug: string
        lang: string
        sorting?: string
        segment: string
    }
    const [frontpages, pages, blogPosts] = await Promise.all([
        client.fetch<PageObject[]>(frontpageQuery),
        client.fetch<PageObject[]>(pageQuery),
        client.fetch<PageObject[]>(blogPostQuery),
    ])

    // Split the slug strings to arrays (as required by Next.js)
    const paths = [
        ...frontpages,
        ...pages,
        ...blogPosts,
    ].reduce((collector, { slug, lang, segment }) => {
        const pageSlug = [segments[lang][segment]]

        if (slug) {
            pageSlug.push(...slug.split("/").filter((p) => p))
        }

        if (lang) {
            collector.push({
                params: {
                    slug: pageSlug,
                },
                locale: lang,
            })
        }

        return collector
    }, [] as { params: { slug: string[] }, locale: string }[])

    return {
        paths,
        fallback: "blocking",
    }
}
