
import React, { useRef } from "react"
import { useSettings } from "src/layout/page/settingsContext"
import CountryCodeSelect from "./CountryCodeSelect"
import s from "./SendSms.module.scss"
import cn from "classnames"
import { getTranslation } from "src/lib/translations"

export default function PhoneInput({ phone, setPhone, className, children }: { phone: [string, string], setPhone: React.Dispatch<React.SetStateAction<string[]>>, className?: string, children?: React.ReactNode}) {
    const { lang } = useSettings()
    const phoneInputRef = useRef<HTMLInputElement>()
    const t = getTranslation("downloadOverlay", lang)

    function updatePhone(e: React.ChangeEvent<HTMLInputElement>) {
        const rawPhone = e.target.value
        const threeDigitCountryCodes = ["+29", "+35", "+37", "+38", "+42"] // Country codes that start with one of these are actual 3 digit (example: Ireland is +353)
        const phoneStartsWithThreeDigitCountryCode = threeDigitCountryCodes.find(cc => rawPhone.startsWith(cc))
        const countryCodeLength = phoneStartsWithThreeDigitCountryCode ? 4 : 3
        const parsedCountryCode = rawPhone.startsWith("+") ? rawPhone.slice(0, countryCodeLength) : phone[0]
        const cleanPhone = (rawPhone.startsWith("+") ? rawPhone.slice(countryCodeLength) : rawPhone).replace(/[^\d]/g, "")
        setPhone([parsedCountryCode, cleanPhone])
    }

    return (
        <div className={cn(s["send-sms__input"], className)}>
            <div className={s["send-sms__input__prefix"]}>
                <CountryCodeSelect phoneInputRef={phoneInputRef} countryCode={phone?.[0]} setCountryCode={(newCountryCode) => setPhone((oldPhoneValue) => [newCountryCode, oldPhoneValue[1]])} />
            </div>
            <input
                className={s["send-sms__input__input"]}
                // required
                type="tel"
                name="phone"
                inputMode="tel"
                maxLength={ 12 }
                value={phone[1]}
                onChange={updatePhone}
                placeholder={t.inputYourPhoneNumber}
            />
            {children}
        </div>
    )
}
