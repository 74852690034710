import React from "react"
import cn from "classnames"
import s from "./ButtonGroup.module.scss"

interface ButtonGroupProps {
    children: React.ReactNode
    className?: string
}

export default function ButtonGroup({ children, className }: ButtonGroupProps) {
    return (
        <div className={cn(s["button-group"], className)}>
            {children}
        </div>

    )
}
