import { SanityImage, SanityMedia } from "./types"
import { urlFor } from "./sanity"

export function getUrl({ url, hotspot, crop, width, height, quality = 80, format }) {
    let builtUrl = urlFor({ asset: { url: url }, hotspot, crop })
        .format(format)
        .width(Math.floor(width))
        .height(Math.floor(height))
        .maxHeight(2000) // Always max height 2000
        .quality(width > 1600 ? 90 : quality) // Use higher quality pictures for desktop, which can handle the bandwidth

    if (format !== "svg" && !url.includes(".svg")) {
        builtUrl = builtUrl.auto("format")
    }

    return builtUrl.url()
}

export function getAspectRatioOMedia(media: SanityMedia) {
    let crop = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    }
    if (media.type === "image")
        crop = {
            top: media?.crop?.top || 0,
            bottom: media?.crop?.bottom || 0,
            left: media?.crop?.left || 0,
            right: media?.crop?.right || 0,
        }

    return (media.width * (1 - crop.left - crop.right)) / (media.height * (1 - crop.bottom - crop.top))
}

interface SanityImageArgs {
    image: SanityImage
    width: number
    height: number
    step?: number
    minWidth?: number
    minHeight?: number
    format?: "jpg" | "png"
}

export function generateSanityImage({
    image,
    width,
    height,
    step = 200,
    minWidth = 300,
    format,
}: SanityImageArgs) {
    if (!image || !image.src) {
        return null
    }

    const ratio = height / width
    const srcSet = []
    const isSvg = image.src.endsWith(".svg")

    for (let size = minWidth; (size < (width + step)) && size < 2300 ; size += step) {
        if (width) {
            srcSet.push([size, size * ratio])
        } else {
            srcSet.push([size * ratio, size])
        }
    }

    return {
        src: getUrl({ url: image.src, hotspot: image.hotspot, crop: image.crop, height, width, format }),
        ...(!isSvg ? { srcSet: srcSet.map(([width, height]) => `${getUrl({ url: image.src, width, height, format, hotspot: image.hotspot, crop: image.crop })} ${width}w`) } : { srcSet: [getUrl({ url: image.src, hotspot: image.hotspot, crop: image.crop, height, width, format })] }),
        alt: image.alt || "",
        width: width ? width : height * ratio,
        height: height ? height : width * (ratio),
        placeholder: image.placeholder,
    }
}
