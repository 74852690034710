import React, { useEffect, useRef, useState } from "react"
import cn from "classnames"
import s from "./Image.module.scss"

interface ImageProps {
    width: number
    height: number
    placeholder?: string
    alt?: string
    lazy?: boolean
    ignorePlaceholder?: boolean
    src: string
    srcSet?: string[]
    sizes: string[]
    className?: string
}

export default function Image({
    width,
    height,
    placeholder,
    alt = "",
    lazy = true,
    ignorePlaceholder = false,
    src,
    srcSet = [],
    sizes = [],
    className = "",
}: ImageProps): JSX.Element {
    const [loaded, setLoaded] = useState(false)
    const image = useRef<HTMLImageElement>()

    useEffect(() => {
        if (image.current?.complete) {
            setLoaded(true)
        }
    }, [])

    return (
        <div
            aria-hidden
            style={{
                // aspectRatio: height && width ? width / height : undefined,

            }}
            className={cn(s.image, className)}
        >
            <div
                className={cn(!loaded ? s["image__blur"] : null)}
                style={{
                    backgroundImage: ignorePlaceholder ? undefined : loaded ? undefined : `url(${placeholder})`,
                }}
            />
            <img
                className={cn(s.image__src)}
                src={src}
                alt={alt}
                onLoad={() => setLoaded(true)}
                loading={lazy ? "lazy" : undefined}
                ref={image}
                srcSet={ srcSet ? srcSet.join(", ") : undefined }
                sizes={sizes.join(", ")}
                style={{
                    aspectRatio: !loaded ? (height && width ? width / height : undefined) : undefined,
                    opacity: loaded ? 1 : 0,
                }}
                aria-hidden
            />
        </div>
    )
}
