import React, { useEffect, useState } from "react"
import cn from "classnames"
import s from "./DownloadOverlay.module.scss"
import Icon, { IconType } from "src/components/icon/Icon"
import SendSms from "src/components/send-sms/SendSms"
import { useSettings } from "../../layout/page/settingsContext"
import { useOverwrites } from "../../layout/page/overwriteContext"
import { useListener } from "react-bus"
import { Container } from "src/layout/container/Container"
import Router from "next/router"
import CallToAction from "../call-to-action/CallToAction"
import { getGaIds, trackGTMEvent } from "src/lib/tagmanager"
import Script from "next/script"
import { isMobile } from "src/utils"
import { createAppLinkUrl } from "src/lib/oneclick/util"
import { useOneclickTracking } from "src/lib/oneclick"
import { onConsentReady } from "src/lib/cookieinformation"
import BusinessSignup from "../send-sms/BusinessSignup"
import InputField from "../input-field/InputField"
import { getTranslation } from "src/lib/translations"

interface DownloadOverlayProps {
    className?: string
}

export default function DownloadOverlay({ className }: DownloadOverlayProps) {
    const [open, setOpen] = useState(false)
    const [delayedLoadRecaptcha, setDelayedLoadRecaptcha] = useState(false)
    const { translations, lang, headerCTALabel: title, segment, downloadDetails: downloadOverlayDefault } = useSettings()
    const { headerDownloadCTA, downloadOverlay: downloadOverlayOverwrite } = useOverwrites()
    const downloadDetails = { ...downloadOverlayDefault, ...(downloadOverlayOverwrite ?? {}) }
    const [overlayType, setOverlayType] = useState<"download" | "contact-me" | "sas">("download")
    const t = getTranslation("downloadOverlay", lang)

    const { ids, source, campaign, parsedSource } = useOneclickTracking()
    const appLinkParams = { ids, source, campaign }
    const [appStoreLink, setAppStoreLink] = useState("")

    Router.events.on("routeChangeStart", () => setOpen(false))
    Router.events.on("routeChangeComplete", () => setOpen(false))
    Router.events.on("routeChangeError", () => setOpen(false))

    useListener("downloadOverlay", async (event) => {
        if (window.location.pathname.includes("sas-eurobonus")) {
            setOverlayType("sas")
            setOpen(true)
            return
        }
        const isContactMeEvent = segment === "business" && event && typeof event === "string" && event.toLowerCase().match(/\b(kontakt|banktjek|talk|salg|contact)\b/)
        setOverlayType(isContactMeEvent ? "contact-me" : "download")

        await Promise.race([onConsentReady(), new Promise(resolve => setTimeout(resolve, 1000))])
        const gaIds = await getGaIds()
        const newAppStoreLink = createAppLinkUrl({
            ...appLinkParams,
            ids: { ...ids, ...gaIds },
            ...(downloadDetails?.onelink ? { onelinkUrl: downloadDetails?.onelink } : {}),
        })
        setAppStoreLink(newAppStoreLink)
        // On consumer mobile, we skip download overlay and open the onelink immediately
        if (isMobile && segment === "private") {
            trackGTMEvent("app-badge-click", { install_lead_type: "mobile-cta-direct-to-app-store" })
            location.href = newAppStoreLink
            return
        }
        setOpen(true)
    })
    useListener("contactOverlay", async () => {
        setOverlayType("contact-me")
        setOpen(true)
    })

    useEffect(() => {
        if (window.location.search.includes("openOverlay")) {
            setOpen(true)
        }
    }, [])

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [open])

    useEffect(() => {
        const closeOverlayOnEsc = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                setOpen(false)
            }
        }
        document.addEventListener("keyup", closeOverlayOnEsc)

        return () => document.removeEventListener("keyup", closeOverlayOnEsc)
    }, [])

    useEffect(() => {
        // used to lazy load recaptcha
        setTimeout(() => setDelayedLoadRecaptcha(true), 3000)
    }, [])

    const handleOpenDownloadoverlayWindowEventListener = async function(event) {
        if (event?.data?.href === "#do") {
            // If Sleeknote button is linking to "#do", we open the Download Overlay and close the Sleeknote popup
            setOpen(true)
            if ((window as any).SleekNote) {
                const sleeknoteapi = await (window as any).SleekNote.API
                sleeknoteapi("close", event?.data?.campaignId)
                // Remove #do from url
                history.replaceState("", document.title, window.location.pathname + window.location.search)
            }
        }
    }

    useEffect(() => {
        window.addEventListener("sleekNote", handleOpenDownloadoverlayWindowEventListener)

        return () => {
            window.removeEventListener("sleekNote", handleOpenDownloadoverlayWindowEventListener)
        }
    }, [])

    return (
        <div className={cn(s["download-overlay"], s[`download-overlay--segment-${segment}`])} data-module-name={"DownloadOverlay"} >
            {(!headerDownloadCTA?.cta) && (
                <button
                    className={cn(s["download-overlay__button"], className, "button", "button--pill")}
                    onClick={() => setOpen(i => !i)}
                >
                    {title}
                </button>
            )}
            {headerDownloadCTA?.cta && (
                <CallToAction {...headerDownloadCTA?.cta}
                    className={cn(s["download-overlay__button"])}
                    appearance={segment === "business" ? "light" : null}
                />
            )}

            <div
                className={cn(
                    s["download-overlay__popup"],
                    {
                        "bg-primary-green": segment === "private",
                        [s["download-overlay__popup--partly"]]: segment === "business",
                        [s["download-overlay__popup--open"]]: open,
                    },
                )}
            >
                <div className={cn(s["download-overlay__backdrop"])} onClick={() => setOpen(false)} />
                <Container className={cn(s["download-overlay__content"])}>
                    <button
                        onClick={() => setOpen(false)}
                        className={cn(s["download-overlay__close"], "button", "button--close")}
                    >
                        <Icon type={IconType.X} />
                        <span className="visually-hidden">{translations?.languages?.close}</span>
                    </button>

                    {overlayType === "sas" && (
                        <SASPreScreen t={t} lang={lang} setOverlayType={setOverlayType} ids={ids} parsedSource={parsedSource} />
                    )}
                    {overlayType === "download" && (
                        segment === "business"
                            ? ( <BusinessSignup downloadDetails={downloadDetails} className={s["download-overlay__inner-content"]}  /> )
                            : ( <SendSms {...downloadDetails} appStoreLink={appStoreLink} className={s["download-overlay__send-sms"]} /> )
                    )}
                    {overlayType === "contact-me" && (
                        <BusinessSignup downloadDetails={downloadDetails} signupType={"contact-me"} className={s["download-overlay__inner-content"]}  />
                    )}

                    {/* <h2 className={cn(s["download-overlay__mobile-title"], "heading-200")}>{downloadDetails.title}</h2> */}

                    {/* <Steps steps={downloadDetails.steps.map(step => ({ text: stringToRichText(step) }))} className={s["download-overlay__steps"]} /> */}

                    {/* <div className={s["download-overlay__store"]}>
                        {open && (<AppStore
                            linkAppStore={linkAppStore}
                            linkGooglePlay={linkGooglePlay}
                            orientation="horizontal"
                            onelinkUrl={downloadOverlayOverwrite?.onelink}
                        />)}
                    </div> */}

                    <small className={cn(s["download-overlay__recaptcha-disclaimer"], "paragraph-50")}><a href="https://policies.google.com/privacy">Google</a> <a href="https://policies.google.com/terms">reCAPTCHA</a></small>
                    {delayedLoadRecaptcha && <Script strategy="lazyOnload" defer={true} src="https://www.google.com/recaptcha/api.js?render=6LfdMsUhAAAAAGicH2UnCNbICPSMUtPBDyb6M0pI" />}
                </Container>
            </div>
        </div>
    )
}


function SASPreScreen({ t, setOverlayType, lang, ids, parsedSource }) {
    async function getRecaptchaToken() {
        return new Promise((resolve) => {
            window.grecaptcha.ready(async function() {
                window.grecaptcha.execute("6LfdMsUhAAAAAGicH2UnCNbICPSMUtPBDyb6M0pI", { action: "submit" }).then(async function(recaptchaClientToken) {
                    resolve(recaptchaClientToken)
                })
            })
        })
    }

    return (
        <form
            className="text-center"
            onSubmit={async (e) => {
                e.preventDefault()
                const recaptchaClientToken = await getRecaptchaToken()
                const email = document.querySelector<HTMLInputElement>(".emailInput input")?.value
                if (!email) {
                    return
                }
                const gaIds = await getGaIds()
                const body = {
                    actions: "createLead",
                    email: email.toLowerCase(),
                    lang,
                    segment: "private",
                    ids: { ...gaIds, ...ids },
                    utm_source: parsedSource,
                    referrer: location.href.split("?")[0].split("#")[0],
                    marketingConsent: true,
                    leadSource: "web-sas-eurobonus",
                    recaptchaClientToken,
                    deviceType: isMobile ? "mobile" : "desktop",
                }
                setOverlayType("download")
                trackGTMEvent("signup_create_lead", {
                    install_lead_type: "sas-eurobonus",
                })
                await fetch(`/api/createLead`, {
                    body: JSON.stringify(body),
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
            }}
        >
            <h2 className="heading-200 mb-large">{t.sasHeadline}</h2>
            <p className="paragraph-100 mb-large">{t.sasSubtitle}</p>
            <InputField
                type="email"
                name="email"
                // label={"Email"}
                placeholder={t.emailPlaceholderConsumer}
                // onChange={() => void 0}
                className="mb-medium emailInput"
                required
            >
            </InputField>
            <style dangerouslySetInnerHTML={{ __html: `.emailInput {max-width: 400px; margin-left: auto; margin-right: auto; } .emailInput input {border-radius:100px; padding: 13px 24px; }` }} />
            <button type="submit" name="submit_mobile" className={cn("button button--pill", "mb-xlarge", "hide-on-desktop")} style={{ width: "100%" }}>
                {t.getStartedCta}
            </button>
            <button type="submit" name="submit_desktop" className={cn("button button--pill", "mb-xlarge", "hide-on-mobile")}>
                {t.getStartedCta}
            </button>
            <div className={cn("paragraph-50")} style={{ fontSize: "12px" }}>{t.consumerSignupDisclaimer}</div>
        </form>
    )
}
