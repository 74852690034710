import s from "./SoMeLinksBlock.module.scss"

import React from "react"
import { Link } from "../link/Link"
import Icon, { IconType } from "../icon/Icon"

interface SoMeLinksBlockProps {
    nav: {
        title: string
        url: string
    }[]
}

export default function SoMeLinksBlock({ nav }: SoMeLinksBlockProps) {
    return (
        <ul className={s["some-links-block"]}>
            {nav.map((i, index) => {
                return (
                    <li key={index} className={s["some-links-block__item"]}>
                        <Link href={i.url}>
                            <Icon type={i.title.toLocaleLowerCase() as IconType} />
                            <span className="visually-hidden">{i.title}</span>
                        </Link>
                    </li>
                )
            })}
        </ul>
    )
}
