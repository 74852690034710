/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, Dispatch, SetStateAction, useContext, useState } from "react"
import { SanityCallToAction, DownloadOverlay } from "../../sanity/types"
import { useSettings } from "./settingsContext"

type OverwriteContextType = {
    headerDownloadCTA: {
        cta: SanityCallToAction | null
        setCTA: Dispatch<SetStateAction<SanityCallToAction | null>>
        resetCTA: () => void
    }
    downloadOverlay: DownloadOverlay
    setDownloadOverlay: Dispatch<SetStateAction<DownloadOverlay | null>>
    resetDownloadOverlay: () => void
}

export const OverwriteContext = createContext<OverwriteContextType>({
    headerDownloadCTA: {
        cta: null,
        setCTA: () => {},
        resetCTA: () => {},
    },
    downloadOverlay: null,
    setDownloadOverlay: () => {},
    resetDownloadOverlay: () => {},
})

export function useOverwrites() {
    const context = useContext(OverwriteContext)

    return context
}

export const OverwriteProvider = ({ children }) => {
    const { headerCTALabel, downloadOverlay: defaultDownloadOverlay } = useSettings()
    const defaultCTA: SanityCallToAction  = {
        style: "button",
        link: {
            id: null,
            event: "downloadOverlay",
            title: headerCTALabel,
            linkType: "event",
            type: "link",
        },
    }

    const [headerDownloadCTA, setHeaderDownloadCTA] = useState<SanityCallToAction | null>(defaultCTA)
    const [downloadOverlay, setDownloadOverlay] = useState<DownloadOverlay | null>(defaultDownloadOverlay)

    const context: OverwriteContextType = {
        headerDownloadCTA: {
            cta: headerDownloadCTA,
            setCTA: setHeaderDownloadCTA,
            resetCTA: () => setHeaderDownloadCTA(defaultCTA),
        },
        downloadOverlay,
        setDownloadOverlay,
        resetDownloadOverlay: () => setDownloadOverlay(defaultDownloadOverlay),
    }

    return (
        <OverwriteContext.Provider value={context}>
            {children}
        </OverwriteContext.Provider>
    )
}
