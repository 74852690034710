import React, { useEffect, useRef, useState } from "react"
import cn from "classnames"
import s from "./LoginOverlay.module.scss"
import Icon, { IconType } from "src/components/icon/Icon"
import { createFocusTrap } from "focus-trap"
import { Link } from "src/components/link/Link"
import { useSettings } from "../../layout/page/settingsContext"
import { useListener } from "react-bus"
import { InternalLink } from "../../sanity/types"
import buildLink from "../../sanity/buildLink"
import { Container } from "src/layout/container/Container"
import Router, { useRouter } from "next/router"
import AppStore from "../app-store/AppStore"

interface LoginOverlayProps {
    className?: string
}

export default function LoginOverlay({ className }: LoginOverlayProps) {
    const [open, setOpen] = useState(false)
    const { translations: t, loginDetails, segment, lang } = useSettings()
    Router.events.on("routeChangeStart", () =>  setOpen(false))
    Router.events.on("routeChangeComplete", () =>  setOpen(false))
    Router.events.on("routeChangeError", () =>  setOpen(false))

    const homeLink: InternalLink = {
        id: "",
        title: "",
        linkType: "internal",
        type: "link",
        url: {
            type: "frontpage",
            lang,
            segment,
            slug: "",
        },
    }

    const ref = useRef<HTMLDivElement>()

    const router = useRouter()

    /** To delete after release at 3.03.2022 if last login view won't be necessary */
    // const loginRedirect = React.useCallback(() => {
    //     bus.emit("bankOverlay")
    //     setOpen(false)
    // }, [bus])


    const loginRedirect = React.useCallback(() => {
        /** Temporary hardcoded - to check if client wants to set this url */
        router.push("https://se.lunar.app/logga-in")
    }, [router])

    useListener("loginOverlay", () => {
        setOpen(true)
    })

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [open])

    useEffect(() => {
        if (open) {
            const trap = createFocusTrap(ref.current, {
                onDeactivate: () => setOpen(false),
            })

            trap.activate()

            return () => {
                trap.deactivate()
            }
        }
    }, [open])

    return (
        <div className={cn(s["login-overlay"], className)} >

            <div
                ref={ref}
                style={{ opacity: open ? "1" : "0", pointerEvents: open ? "all" : "none" }}
                className={cn(s["login-overlay__popup"], "blur", { [s["login-overlay__popup--closed"]]:!open })}
            >
                <Container>

                    <button
                        onClick={() => setOpen(false)}
                        className={cn(s["login-overlay__close"], "button", "button--close")}
                    >
                        <Icon type={IconType.X} />
                        <span className="visually-hidden">{t?.languages?.close}</span>
                    </button>

                    <Link href={buildLink(homeLink)} className={cn(s["login-overlay__logo"])}>
                        <Icon className={s["login-overlay__logo__svg"]} type={IconType.LOGO} />
                        <span className="visually-hidden">
                            Lunar {t?.header?.home.toLocaleLowerCase()}
                        </span>
                    </Link>

                    <div className={s["login-overlay__container"]}>
                        <h2 className="heading-200">{loginDetails?.accountTitle}</h2>
                        <div className={s["login-overlay__actions"]}>
                            <div className={cn(s["login-overlay__apps"], s["login-overlay__cta"])}>
                                <h2>{loginDetails?.appAccountTitle}</h2>
                                <p>{loginDetails?.appAccountCTA}</p>
                                <div>
                                    <AppStore className={s["login-overlay__store"]} orientation="horizontal" />
                                </div>
                            </div>
                            <div className={cn(s["login-overlay__login"], s["login-overlay__cta"])}>
                                <h2>{loginDetails?.loginTitle}</h2>
                                <p>{loginDetails?.loginCTA}</p>
                                <button onClick={loginRedirect}>{loginDetails?.loginButton}</button>
                            </div>
                        </div>
                    </div>

                </Container>
            </div>
        </div>
    )
}
