class FetchyError extends Error {
    body: any
    status: number

    constructor(url: string, status: number, body: any, message = "Oops error") {
        super(`${message}: ${status} ${url}`)

        this.status = status
        this.body = body
    }
}

interface FetchyOptions extends RequestInit {
    query?: any
}

export default async function fetchy<R>(
    url: string,
    options: FetchyOptions = {},
): Promise<R> {
    const qs = new URLSearchParams(options.query)
    const querystring = qs.toString() ? `?${qs.toString()}` : ""

    const response = await fetch(url + querystring, {
        method: "GET",
        headers: { "content-type": "application/json", ...options.headers },
        ...options,
    })

    if (response?.ok) {
        let body = await response.text()

        try {
            body = JSON.parse(body || null)
        } catch (e) {
            throw new FetchyError(url, response.status, body, "Error parsing body")
        }

        return body as unknown as R
    } else {
        let body = await response.text()

        try {
            body = JSON.parse(body || null)
        } catch (e) {
            throw new FetchyError(url, response.status, body, "Error parsing body")
        }

        throw new FetchyError(url, response.status, body)
    }
}

fetchy.post = function <R>(url: string, body: any, options?: FetchyOptions): Promise<R> {
    return fetchy<R>(url, {
        method: "POST",
        body: JSON.stringify(body),
        ...options,
    })
}
