import React from "react"
import cn from "classnames"
import s from "./VideoLightweight.module.scss"
import { SanityVimeoFiles } from "../../sanity/types"
import { useInView } from "react-intersection-observer"
interface VideoLightweightProps extends SanityVimeoFiles {
    containerSize: [number, number]
    sizes: string[]
}

// "smart" - can take a width and height to set custom ratio and will then fill the entire
// box potentially overflowing and cropping video
export default function VideoLightweight({
    width,
    height,
    videoFiles,
    autoplay,
    containerSize,
    // sizes,
    loop,
    controls,
    thumbnail,
}: VideoLightweightProps): JSX.Element {
    const { ref: inViewRef, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "500px",
    })
    // const [isPlaying, setIsPlaying] = useState(false)

    const videoRatio = width / height
    const containerRatio = containerSize[0] / containerSize[1]

    let w, h
    if (containerRatio > videoRatio) {
        // Container is wider than video
        w = "100%"
        h = Math.ceil(containerRatio / videoRatio * 100) + "%"
    } else {
        // Video is wider than container
        w = Math.ceil(videoRatio / containerRatio * 100) + "%"
        h = "100%"
    }

    // const m3u8File = videoFiles.find(videoFile => videoFile.link.includes(".m3u8"))

    // Sort for the smallest size
    videoFiles?.sort((a, b) => a.width - b.width)
    const largeEnoughFiles = videoFiles?.filter((videoFile) => !videoFile.link.includes(".m3u8") && videoFile.width > Math.min(containerSize[0], 1920))
    const bestSource = largeEnoughFiles?.[0] || videoFiles?.filter(videoFile => !videoFile.link.includes(".m3u8")).slice(-1)[0]
    const vimeoThumbnailSizes = [
        "100x75",
        "200x150",
        "295x166",
        "640x360",
        "960x540",
        "1280x720",
        "1920x1080",
    ]
    const thumbnailSrcSet = vimeoThumbnailSizes.map((size) => `${thumbnail}_${size.split("x")[0]} ${size.split("x")[0]}w`)
    return (
        <div
            className={cn(s["video-lightweight"], "video-wrapper")}
            style={{
                aspectRatio: containerSize[0] && containerSize[1] ? containerSize[0] / containerSize[1] : undefined,
                // ...(thumbnail ? { "backgroundImage": `url(${thumbnail})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" } : {}),
                "--aspect-ratio-width": w,
                "--aspect-ratio-height": h,
            } as React.CSSProperties}
            ref={inViewRef}
        >
            {/* <img
                thumbnailSrcSet={thumbnailSrcSet.join(", ")}
                src={thumbnailSrcSet[4]} //540p

                {...(sizes ? { sizes: sizes.join(", ") } : {})}

                loading="lazy"
                alt=""
                style={{ display: isPlaying ? "none" : "block" }}
            /> */}
            {inView && (
                <video poster={thumbnailSrcSet[4]} controls={controls} autoPlay={autoplay} muted={autoplay} playsInline={autoplay || controls} loop={loop} disableRemotePlayback>
                    {[bestSource].filter(Boolean).map(videoFile => (
                        <source key={videoFile.link} src={videoFile.link} type={videoFile.link.includes(".m3u8") ? "application/x-mpegURL" : "video/mp4"} />
                    ))}
                </video>
            )}
        </div>
    )
}
