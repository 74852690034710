const Config = {
    BASE_URL: process.env.NEXT_PUBLIC_BASE_URL || (process.env.NEXT_PUBLIC_VERCEL_URL ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}` : false) || "http://localhost:3000",
    USE_CDN: process.env.USE_CDN === "true",
    APP_ENV: process.env.NEXT_PUBLIC_APP_ENV || "development",
    SANITY_DATASET: process.env.NEXT_PUBLIC_SANITY_DATASET || "development",
    SANITY_PROJECT_ID: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID || process.env.STORYBOOK_NEXT_PUBLIC_SANITY_PROJECT_ID as string,
    SANITY_API_TOKEN: process.env.SANITY_API_TOKEN,
    SANITY_PUBLISH_API_TOKEN: process.env.SANITY_PUBLISH_API_TOKEN,
    SANITY_PREVIEW_SECRET: process.env.SANITY_PREVIEW_SECRET,
    SANITY_WEBHOOK_SECRET: process.env.SANITY_WEBHOOK_SECRET,
    EXCHANGE_RATE_API_TOKEN: "b5af5a577013e3018bcc0c56", // free tier, can be replaced
    CORS_ALLOW_ORIGIN: process.env.CORS_ALLOW_ORIGIN || "*",
}

export default Config
