import { useRouter } from "next/router"
import React from "react"
import s from "./SegmentSelector.module.scss"
import cn from "classnames"
import { useSettings } from "../page/settingsContext"
import { LinkHandler } from "../../components/link-handler/LinkHandler"

export default function SegmentSelector({
    dark = false,
    className,
}: { dark?: boolean, className?: string}) {

    const router = useRouter()
    const { settings, segment: currentSegment, lang } = useSettings()

    const segmentOrder: Record<Sanity.Schema.Segment, number> = {
        "private": 1,
        "business": 2,
    }
    const segments = settings.filter((e) => e.lang === lang).sort((a, b) => segmentOrder[a.segment] - segmentOrder[b.segment])

    return (
        <ul className={cn(s["segment-selector"], { [s["segment-selector--dark"]]: dark }, className)}>
            {segments.length >= 2 && segments.map((e, index) => (
                <li key={index}
                    className={cn(s["segment-selector__item"], {
                        [s["segment-selector__item--active"]]: e.segment === currentSegment,
                    })}
                    aria-current={ e.segment === currentSegment }
                >
                    <LinkHandler
                        className={cn(s["segment-selector__link"], "label-100")}
                        link={e.link}
                        locale={router.locale}
                        prefetch={false}
                    >
                        {e.title}
                    </LinkHandler>
                </li>
            ))}
        </ul>
    )
}
