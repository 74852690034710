
import cn from "classnames"
import React, { useEffect, useState } from "react"
import s from "./SendSms.module.scss"
import fetch from "src/api/fetch"
import { DownLoadDetails } from "src/sanity/types"
import { useOneclickTracking } from "../../lib/oneclick"
import { useSettings } from "src/layout/page/settingsContext"
import { getGaIds, trackGTMEvent } from "../../lib/tagmanager"
import { captureException } from "@sentry/nextjs"
import { useInView } from "react-intersection-observer"
import { getCallToActionTrackingHandler } from "src/components/call-to-action/CallToAction"
import countryCodes from "./countrycodes"
import PhoneInput from "./PhoneInput"
import { getDefaultCountryCode } from "./CountryCodeSelect"
import { useQRCode } from "next-qrcode"
import { createDeferredDownloadLink } from "src/lib/oneclick/util"
import { getTranslation } from "src/lib/translations"
import AppStore from "../app-store/AppStore"

interface SendSmsProps extends DownLoadDetails {
    className?: string
    appStoreLink?: string
    forceSimple?: boolean
}

export default function SendSms({
    title,
    subtitle,
    text,
    successMessage,
    errorMessage,
    className,
    ctaText,
    onelink,
}: SendSmsProps) {
    const [phone, setPhone] = useState<[string, string]>(["", ""])
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const { lang, segment } = useSettings()
    const { ids, source, campaign } = useOneclickTracking()
    const [qrLink, setQrLink] = useState<string>(createDeferredDownloadLink({ ids: {}, lang, linkSource: "qr", segment }))
    const t = getTranslation("downloadOverlay", lang)

    const { ref: inViewRef, inView } = useInView({
        threshold: 0,
        rootMargin: "500px",
    })

    const Canvas: any = useQRCode().Canvas

    useEffect(() => {
        if (inView) {
            (async () => {
                const gaIds = await getGaIds()
                setQrLink(createDeferredDownloadLink({ ids: { ...ids, ...gaIds }, linkSource: "qr", segment, lang, source, campaign }))
            })()
        }
    }, [inView])

    async function getRecaptchaToken() {
        return new Promise((resolve) => {
            window.grecaptcha.ready(async function() {
                window.grecaptcha.execute("6LfdMsUhAAAAAGicH2UnCNbICPSMUtPBDyb6M0pI", { action: "submit" }).then(async function(recaptchaClientToken) {
                    resolve(recaptchaClientToken)
                })
            })
        })
    }

    async function submitForm() {
        setSuccess(false)
        setError("")
        setIsLoading(true)
        const recaptchaClientToken = await getRecaptchaToken()
        const gaIds = await getGaIds()

        const body = {
            actions: [
                phone[1].length > 0 ? "sendSms" : null,
            ].filter(Boolean),
            phone: phone.join(""),
            lang,
            segment,
            referrer: location.href,
            ids: { ...ids, ...gaIds },
            ...(onelink ? { onelinkPath: new URL(onelink).pathname.slice(1) } : {}),
            utm_source: source,
            utm_campaign: campaign,
            recaptchaClientToken,
        }

        try {

            await fetch(`/api/createLead`, {
                body: JSON.stringify(body),
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            })
            setError("")
            setSuccess(true)
            setTimeout(() => {setSuccess(false)}, 5000)

            if (body.actions.includes("sendSms")) {
                const didChangeCountryCode = getDefaultCountryCode(lang).value !== phone[0]
                trackGTMEvent("send-link-by-sms", {
                    sms_phone_number_country_code: phone[0].replaceAll("+", ""),
                    sms_phone_number_country: countryCodes.find(cc => cc.phoneCode === phone[0])?.name.en || phone[0].replaceAll("+", ""),
                    sms_phone_number: phone[1],
                    sms_did_change_country_code: didChangeCountryCode,
                    gclid: ids.gclid,
                    install_lead_type: "sms",
                })
                setTimeout(() => setPhone((oldPhone) => [oldPhone[0], ""]), 1000)
            }

        } catch (e) {
            console.error(e)
            setError(errorMessage)
            setSuccess(false)
            captureException(e)
        }
        setIsLoading(false)


    }


    return (
        <section className={cn(s["send-sms"], className)} ref={inViewRef}>
            {title && <h2 className={cn(s["send-sms__title"], "heading-200")}>{title}</h2>}
            {subtitle && (<p className={cn(s["send-sms__subtitle"], "paragraph-100")}>{subtitle}</p>)}

            <div className="hide-on-mobile">
                <p className={cn(s["send-sms__subtitle"], "paragraph-100")}>{t.scanQrCode}</p>
                <div className="hide-on-mobile center-content mb-xlarge">
                    <Canvas
                        text={qrLink}
                        options={{
                            width: 250,
                            errorCorrectionLevel: "L",
                            margin: 2,
                        }}
                    />
                </div>
                <p className={cn(s["send-sms__subtitle"], "paragraph-100")}>{t.orGetSms}</p>

                <form
                    onSubmit={async (e) => {
                        e.preventDefault()
                        await submitForm()
                    }}
                >

                    <PhoneInput phone={phone} setPhone={setPhone} className={cn({ [s["send-sms__input--isLoading"]]: isLoading })} >
                        <button type="submit" disabled={phone?.[1]?.length < 8} className={cn(s["send-sms__input__button"], "button button--pill", "hide-on-mobile")}  onMouseDown={getCallToActionTrackingHandler({ title: ctaText, linkType: "event", event: "requestSms" })}>
                            {ctaText}
                        </button>
                    </PhoneInput>
                    {text && (
                        <p className={cn(s["send-sms__subtitle"], "paragraph-100")}>{text}</p>
                    )}
                    {!!success && (
                        <p className={cn(s["send-sms__success"], "label-100")}>{successMessage}</p>
                    )}
                    {error.length > 0 && (
                        <p className={cn(s["send-sms__error"], "label-100")}>{error}</p>
                    )}

                </form>
            </div>
            <div className="hide-on-desktop">
                <AppStore onelinkUrl={onelink} />
            </div>
        </section>
    )
}
