import { CollageModuleProps } from "src/modules/collage-module/CollageModule"
import { BaseModule, GlobalSettings, SanityMedia, SEOData, SEOType } from "./types"

export default function generateSeoData({ globalSettings, data, url }: {
    globalSettings: GlobalSettings
    data?: {
        seo?: SEOData
        title: string
        modules?: BaseModule[]
    }
    url: string
}): SEOData | null {

    // Pull media from the first module and use for og:image meta tag
    const topModule = data?.modules?.[0]
    let topModuleImageSrc
    if (topModule?.type?.includes("heroFullImageModule") && (topModule?.media as SanityMedia)?.type === "image")
        topModuleImageSrc = topModule?.media.src
    if (topModule?.type?.includes("collageModule")) {
        const media = (topModule as unknown as CollageModuleProps)?.backgroundMedia?.media ||  (topModule as unknown as CollageModuleProps)?.box2?.media?.media || (topModule as unknown as CollageModuleProps)?.box1?.media?.media
        if (media?.type === "image")
            topModuleImageSrc = media.src
        if (media?.type === "vimeoFiles")
            topModuleImageSrc = media.thumbnail
    }

    return {
        pageTitle: data?.title || globalSettings.title,
        title: data?.seo?.title || globalSettings.title,
        description: data?.seo?.description || globalSettings.description,
        type: data?.seo?.type as SEOType || globalSettings.seo?.type as SEOType,
        url: url,
        imageUrl: data?.seo?.imageUrl || topModuleImageSrc || globalSettings.seo?.imageUrl,
        noIndex: data?.seo?.noIndex || false, // should be page specific so no need to pull from globalSettings
    }
}
